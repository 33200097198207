//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SpeechBubble from './components/speechBubble.vue';
import PaddedText from './components/paddedText.vue';

export default {
  components: { SpeechBubble, PaddedText },
  props: {
    notAccented: {
      type: Boolean,
      default: false,
    },
    place: {
      type: String,
      default: 'default',
    },
  },
  created() {
    switch (this.place) {
      case 'sale':
        this.i18nKeyPrefix = 'saleNew';
        break;
      default:
        this.i18nKeyPrefix = 'rt0822';
    }
  },
};
